import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Footer from '../components/Footer';
import Layout from '../components/Layout';

import styles from './reports.module.scss';

interface Report {
  name: string;
  childMarkdownRemark: {
    frontmatter: {
      title: string;
      author: string;
      grade: string;
    };
  };
}

const Reports = () => {
  const { reportList }: { reportList: { nodes: Report[] } } = useStaticQuery(graphql`
    {
      reportList: allFile(filter: {extension: {eq: "md"}, relativeDirectory: {eq: "reports"}}) {
        nodes {
          name
          childMarkdownRemark {
            frontmatter {
              title
              author
              grade
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <div className={styles.reportList}>
        <h2>
          Lapring School Gradebook
        </h2>
        <h3>
          Ms. Palmer, Period 3
        </h3>
        <h3>
          Project: &ldquo;History Essay&rdquo;
        </h3>
        <table className={`${styles.table} ${styles.tableDark} ${styles.tableHover}`}>
          <thead>
            <tr>
              <th scope="col">
                Student
              </th>
              <th scope="col">
                Title
              </th>
              <th scope="col">
                Grade
              </th>
            </tr>
          </thead>
          <tbody>
            {reportList.nodes.map(({ name, childMarkdownRemark }) => (
              <tr>
                <td>
                  {childMarkdownRemark.frontmatter.author}
                </td>
                <td>
                  <Link key={name} to={`/reports/${name}`}>
                    {childMarkdownRemark.frontmatter.title}
                  </Link>
                </td>
                <td>
                  {childMarkdownRemark.frontmatter.grade}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </Layout>
  );
};

export default Reports;
